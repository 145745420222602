/*import the fonts from google fonts*/
@import url("https://fonts.googleapis.com/css2?family=Aref+Ruqaa:wght@400;700&family=Mirza:wght@400;700&family=Roboto:wght@400;700&display=swap");

.page-editor-container {
  width: 70dvw;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .page-editor-container {
    width: 90%;
  }
}

.ql-editor .ql-font-roboto {
  font-family: "Roboto";
}

.ql-editor .ql-font-Georgia {
  font-family: "Georgia";
}

.ql-snow .ql-picker.ql-size .ql-picker-label:before {
  content: "12px"; /* default size */
  font-size: 14px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]:before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]:before {
  content: "12px" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]:before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]:before {
  content: "14px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]:before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]:before {
  content: "16px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]:before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]:before {
  content: "18px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]:before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]:before {
  content: "20px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]:before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]:before {
  content: "22px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]:before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]:before {
  content: "24px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="28px"]:before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]:before {
  content: "28px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]:before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]:before {
  content: "32px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="36px"]:before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]:before {
  content: "36px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="40px"]:before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="40px"]:before {
  content: "40px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="44px"]:before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="44px"]:before {
  content: "44px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="48px"]:before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="48px"]:before {
  content: "48px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"],
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"],
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"],
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"],
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"],
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"],
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"],
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"],
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"],
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"],
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"],
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"],
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"],
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"],
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="28px"],
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"],
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"],
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"],
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="36px"],
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"],
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="40px"],
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="40px"],
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="44px"],
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="44px"],
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="48px"],
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="48px"] {
  font-size: 0 !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item:before {
  font-size: 14px; /* or another appropriate size */
}
