/* ... your other styles ... */

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Georgia]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Georgia]::before {
  content: 'Georgia';
  font-family: 'Georgia', serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Roboto]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Roboto]::before {
  content: 'Roboto';
  font-family: 'Roboto', sans-serif;
}

.ql-font-Georgia {
  font-family: 'Georgia', serif !important;
}

.ql-font-Roboto {
  font-family: 'Roboto', sans-serif !important;
}
