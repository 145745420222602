.page-container {
    width: 70dvw;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Adjustments for smaller screens */
  @media (max-width: 768px) {
    .page-container {
      width: 90%;
    }
  }
  
.ql-container.ql-snow  {
    border: none !important; /* Remove border from the editor container */
}