.outlined-button:hover {
  background-color: black;
  color: white;
  cursor: pointer;
  scale: 1.1;
}
.outlined-button {
  transition: 300ms ease-in-out all;
}

button {
  width: min(400px, 80vw);
  margin-top: 1rem;
  height: 35px;
  border-radius: 8px;
  color: white;
  background-color: black;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 1rem;
  transition: all 0.1s ease-in-out;
}

button:hover {
  background-color: #141414;
}

button.scaleOnHover:hover {
  transform: scale(1.05);
}

button:active {
  background-color: #666;
}

button:disabled {
  /* to put the button in the disabled state, add the disabled attribute to the button element like this <button disabled> */
  background-color: #999;
  cursor: not-allowed;
}

/* input: {
    //black outline on focus
    width: "min(400px, 80vw)",
    height: "50px",
    borderRadius: "8px",
    border: "none",
    backgroundColor: "#fff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    marginBottom: "1rem",
    padding: "0 1rem",
    outline: "none",
    fontSize: "1rem", black outline when selected
  }, */

input {
  width: min(400px, 80vw);
  height: 50px;
  border-radius: 8px;
  border: 2px solid transparent;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  padding: 0 1rem;
  outline: none;
  font-size: 1rem;
}
input:focus {
  border: 2px solid black;
  outline: none;
}
